.hobbySectionContainer {
  scroll-snap-align: center;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 400px;

  h2 {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #4a2f72;
    margin-bottom: 20px;
  }

  h2::before {
    content: '💡';
    font-size: 1.5rem;
    margin-right: 10px;
  }
}

.hobbySectionMobile {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.what-i-like-container {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.what-i-like-container h2 {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #4a2f72;
  margin-bottom: 20px;
}

.what-i-like-container h2::before {
  content: '💡';
  font-size: 1.5rem;
  margin-right: 10px;
}

.interests-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  justify-items: center;
  padding: 20px;
}

.interest {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.interest:hover {
  transform: scale(1.1);
}

.interest-icon {
  font-size: 50px;
  margin-bottom: 8px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1));
}

.interest p {
  font-size: 1rem;
  font-weight: 600;
  color: #4a2f72;
  margin-top: 5px;
}

@media (max-width: 1000px) {
  .interests-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
