h4 {
  font-size: 2rem;
  font-weight: bold;
  color: #5e3b76; /* Your theme color */
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: 4px solid #7a5299; /* Subtle left border accent */
  padding-left: 10px;
}
