.container {
  width: 70%;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.container img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensures the image fits well within the container while maintaining aspect ratio */
}

.container .overlayButton {
  position: absolute !important;
  top: 0;
  right: 0;
  min-height: 20;
  margin: 15px 8px 0px 0px;
  background-color: #6c51a4;
  color: white;
  font-size: 95%;
  text-transform: none;
}

.container .hoverMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black mask */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.container:hover .hoverMask {
  opacity: 0.7;
}

.hoverMask {
  span {
    text-wrap: nowrap;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    transition: opacity 0.3s ease;
    z-index: 999999; /* Ensure the text is above other elements */
    text-shadow: 0 0 14px rgba(255, 255, 255, 0.9),
      0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4); /* Glow effect */
  }
}
