.whatIveDone {
  scroll-snap-align: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  margin-top: 10px;
  .projects-container {
    display: flex;
    gap: 30px;
    justify-content: center;

    p {
      margin-bottom: 20px;
      a {
        color: orchid;
        font-weight: bolder;
        font-size: 20px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .project {
      flex: 1;
      padding: 20px;
      background: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    .project h5 {
      font-size: 1.8rem;
      font-weight: bold;
      color: #5e3b76;
      display: flex;
      align-items: center;
      gap: 10px;
      border-left: 4px solid #7a5299;
      padding-left: 10px;
    }

    .project ul {
      margin-top: 15px;
      padding-left: 20px;
    }

    .project ul li {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 10px;
    }

    .project ul li strong {
      color: #5e3b76;
    }
  }
}

.projectsContainerMobile {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@media (max-width: 1200px) {
  .projects-container {
    flex-direction: column;
  }
}
