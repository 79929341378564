.skillListContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  padding-bottom: 0;

  .skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
    padding: 20px;
    list-style: none;
  }

  .skill-card {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
  }

  .skill-card:hover {
    transform: translateY(-5px);
  }

  .skill-icon {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .skill-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .skill-description {
    color: #555;
    font-size: 0.95rem;
    line-height: 1.5;
  }
}

@media (max-width: 1000px) {
  .skillListContainer {
    .skills-container {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      padding-left: 0;
      gap: 0;
    }
  }
}
