.socialsMobileContainer {
  flex-direction: column;
  scroll-snap-align: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-top: 20px;
  margin-top: 30px;
  border-top-width: 1px;
  border-color: lightgray;
  border-top-style: groove;

  .innerContainer {
    flex-direction: row;
    display: flex;
    justify-content: center;
    column-gap: 30px;
    svg {
      background-color: white;
      padding: 4px;
      border-radius: 50%;
      font-size: 45px;
    }
  }
}

.socialMobile {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
